import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import AboutNav from "../components/about-nav"
import Seo from "../components/seo"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query AboutPageQuery {
      site {
        siteMetadata {
          title
          pageTitles {
            about
          }
        }
      }
      about01: file(relativePath: { eq: "about-hero-01.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)
  const { title: siteTitle, pageTitles } = data.site.siteMetadata
  const { about01 } = data

  return (
    <Layout>
      <Seo title={`${siteTitle} | ${pageTitles.about}`} />
      <BackgroundImage
        Tag="section"
        {...convertToBgImage(getImage(about01))}
        className="hero about01"
        role="parallax"
        preserveStackingContext
      >
        <div className="wrapper">
          <h1>{pageTitles.about}</h1>
        </div>
      </BackgroundImage>
      <section className="textual">
        <div className="wrapper">
          <div className="block twothirdcol">
            <div className="block col1">
              <h2>Hello, I am Joyce</h2>
              <StaticImage
                src="../images/instructor-joyce.jpg"
                alt="Joyce"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                className="block alignl"
              />
              <p>
                I have 14 years experience in the Yang style of Tai Chi as well
                as training in the gentle art of Sun style Tai Chi. I am a
                second degree black belt in the Chinese martial art of Kung Fu,
                Qigong and Tai Chi.
              </p>
              <p>
                In 2001 at age 38 I began training as a means to strengthen my
                body and mind and to relieve the daily stress of the teaching
                profession. After receiving my black belts, I began using my
                instructor and martial art training to teach others in the arts.
                I left the education field to open a studio full time in the UK
                in 2010, then relocated to my home state in 2011 to open a
                studio in Newport Beach, CA. The Newport studio closed in 2017
                and I permanently relocated to live and teach in Lake Arrowhead.
              </p>
              <p>
                Tai Chi has become not only a daily exercise but a huge part of
                my life and I continue to further my training with the
                grandmaster of my linage. In the summer of 2014 I trained under
                Master Yuan of the 15th generation of the Daioist Kung Fu
                Academy in Wudang, China. Over the past five years I have worked
                regularly with clients in dependency recovery as well as
                patients undergoing physical limitations and rehabilitation.
              </p>
              <p>
                I currently teach on the mountain with students ranging from 5
                to 85 in all stages of life. I hold weekly private and group
                classes for students at every level in Tai Chi and Qigong.
              </p>
              <h2>I have also worked with&hellip;</h2>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://www.morningsiderecovery.com/morningside-staff"
                  >
                    Morningside Recovery Newport Beach
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://thelanding.crchealth.com/contact-us/staff"
                  >
                    The Landing Sober Living
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://www.solidlandings.com/"
                  >
                    Solid Landings Behavioral Health
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://www.surehavensober.com/treatment/addiction/"
                  >
                    Sure Haven
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://www.rocksolidtreatment.com/mens/addiction/center/"
                  >
                    Rock Solid Recovery
                  </a>
                </li>
              </ul>
            </div>
            <div className="block col2">
              <AboutNav current="about" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage
